import React from "react";
import "./Footer.css";

const Footer = () => {
  /*  function handleClick(event) {
    data.Videos.map((vidx, index) => {
      return axios.post("https://api-xsite.herokuapp.com/atendimentos", {
        id: index,
        likes: 0,
        url: vidx.url,
        tag: vidx.tag,
      });
    });
  }

 function getInfo(event) {
    axios
      .get("https://api-xsite.herokuapp.com/atendimentos")
      .then(function (response) {
        console.log(response.data);
      });
  }*/
  return (
    <footer class="footer text-center">
      <div class="text-center p-3 text">Best Cum Clips © 2025 Copyright</div>
    </footer>
  );
};

export default Footer;
