import React, { useEffect, useState } from "react";
import "./Videos.css";
import { Link } from "react-router-dom";
import axios from "axios";

const Videos = ({ category }) => {
  const [feed, setFeed] = useState(null);
  const URL = "https://apixsite-kevinsoares180s-projects.vercel.app/videos";

  useEffect(() => {
    axios.get(URL).then((response) => {
      setFeed(response.data);
    });
  }, [URL]);

  function giveLike(_id, _likes, _url, _tag) {
    axios.patch(`https://apixsite-kevinsoares180s-projects.vercel.app/videos/${_id}`, {
      id: _id,
      likes: _likes + 1,
      url: _url,
      tag: _tag,
    });
    document.getElementById(_id).innerHTML = `Up Votes: ${_likes + 1}`;
    document.getElementById(`btn${_id}`).onClick = "";
    document.getElementById(`btn${_id}`).className = "btn-block";
  }
  /* function giveDislike(_id, _likes, _url, _tag) {
    axios.patch(`https://api-xsite.herokuapp.com/atendimentos/${_id}`, {
      id: _id,
      likes: _likes - 1,
      url: _url,
      tag: _tag,
    });
    document.getElementById(_id).innerHTML = `Likes: ${_likes + -1}`;
  }*/
  return (
    <div>
      {feed &&
        feed.map((vidx) => {
          return category === "" ? (
            <>
              <div class="d-flex justify-content-center embed-responsive embed-responsive-21by9 tester">
                <div class="thumbnail">
                  <div className="thumbnail">
                    {/*<p className="vid-stats">Video ID: {vidx.id}</p>*/}
                    <p className="vid-stats" id={vidx.id}>
                      Up Votes: {vidx.likes}
                    </p>
                    <button
                      className="btn-true links"
                      id={"btn" + vidx.id}
                      onClick={async () =>
                        await giveLike(vidx.id, vidx.likes, vidx.url, vidx.tag)
                      }
                    >
                      Up Vote
                    </button>
                  </div>
                  <video
                    className="video"
                    controls={true}
                    src={vidx.url}
                  ></video>
                  <Link to={"/" + vidx.tag} className="links">
                    {vidx.tag}
                  </Link>
                </div>
              </div>
            </>
          ) : vidx.tag === category ? (
            <>
              <div class="d-flex justify-content-center embed-responsive embed-responsive-21by9 tester">
                <div class="thumbnail">
                  <div className="thumbnail">
                    {/*<p className="vid-stats">Video ID: {vidx.id}</p>*/}
                    <p className="vid-stats" id={vidx.id}>
                      Up Votes: {vidx.likes}
                    </p>
                    <button
                      className="btn-true links"
                      id={"btn" + vidx.id}
                      onClick={async () =>
                        await giveLike(vidx.id, vidx.likes, vidx.url, vidx.tag)
                      }
                    >
                      Up Vote
                    </button>
                  </div>
                  <video
                    className="video"
                    controls={true}
                    src={vidx.url}
                  ></video>
                  <Link to={"/" + vidx.tag} className="links">
                    {vidx.tag}
                  </Link>
                </div>
              </div>
            </>
          ) : null;
        })}
    </div>
  );
};

export default Videos;
