import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavBar from "./Components/NavBar.js";
import Videos from "./Components/Videos.js";
import "react-bootstrap";
import Footer from "./Components/Footer";

function App() {
  return (
    <BrowserRouter>
      <NavBar></NavBar>
      <Routes>
        <Route path="/" element={<Videos category={""} />}></Route>
      </Routes>
      <Routes>
        <Route path="Swallow" element={<Videos category={"Swallow"} />}></Route>
      </Routes>
      <Routes>
        <Route path="Facials" element={<Videos category={"Facials"} />}></Route>
      </Routes>
      <Routes>
        <Route
          path="Cum-On-Feet"
          element={<Videos category={"Cum-On-Feet"} />}
        ></Route>
      </Routes>
      <Routes>
        <Route
          path="Cum-Kissing"
          element={<Videos category={"Cum-Kissing"} />}
        ></Route>
      </Routes>
      <Routes>
        <Route
          path="Cum-Drink"
          element={<Videos category={"Cum-Drink"} />}
        ></Route>
      </Routes>
      <Routes>
        <Route path="Crempie" element={<Videos category={"Crempie"} />}></Route>
      </Routes>
      <Routes>
        <Route
          path="Anal-Crempie"
          element={<Videos category={"Anal-Crempie"} />}
        ></Route>
      </Routes>
      <Routes>
        <Route
          path="Gloryhole"
          element={<Videos category={"Gloryhole"} />}
        ></Route>
      </Routes>
      <Routes>
        <Route
          path="Fake-Cum"
          element={<Videos category={"Fake-Cum"} />}
        ></Route>
      </Routes>
      <Routes>
        <Route
          path="Close-Ups"
          element={<Videos category={"Close-Ups"} />}
        ></Route>
      </Routes>
      <Routes>
        <Route
          path="Cum-On-Food"
          element={<Videos category={"Cum-On-Food"} />}
        ></Route>
      </Routes>
      <Routes>
        <Route
          path="Cum-On-Hair"
          element={<Videos category={"Cum-On-Hair"} />}
        ></Route>
      </Routes>
      <Routes>
        <Route
          path="Cum-On-Tits"
          element={<Videos category={"Cum-On-Tits"} />}
        ></Route>
      </Routes>
            <Routes>
        <Route
          path="Cum-Spoon"
          element={<Videos category={"Cum-Spoon"} />}
        ></Route>
      </Routes>
      <Routes>
        <Route
          path="Underwater"
          element={<Videos category={"Underwater"} />}
        ></Route>
      </Routes>
      <Routes>
        <Route
          path="Cum-Walk"
          element={<Videos category={"Cum-Walk"} />}
        ></Route>
      </Routes>
      <Routes>
        <Route
          path="Cum-Covered-Fucking"
          element={<Videos category={"Cum-Covered-Fucking"} />}
        ></Route>
      </Routes>
      <Routes>
        <Route path="Bukkake" element={<Videos category={"Bukkake"} />}></Route>
      </Routes>
      <Routes>
        <Route
          path="Cum-Play"
          element={<Videos category={"Cum-Play"} />}
        ></Route>
      </Routes>
      <Routes>
        <Route
          path="Cum-Dislike"
          element={<Videos category={"Cum-Dislike"} />}
        ></Route>
      </Routes>
             <Routes>
        <Route
          path="Cum-Licking"
          element={<Videos category={"Cum-Licking"} />}
        ></Route>
      </Routes>
      <Footer></Footer>
    </BrowserRouter>
  );
}

export default App;
