import React from "react";
import { Navbar, Container, NavDropdown, Nav } from "react-bootstrap";
import "./NavBar.css";
import { Link } from "react-router-dom";
import { useState } from "react";

const NavBar = () => {
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <Navbar className="fixed-top" expanded={expanded}>
        <Container fluid>
          <Navbar.Brand className="title-style">
            <Link className="top-title" to="">
              Best Cum Clips
            </Link>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="navbar" className="dropdown-menu" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="">
              <NavDropdown id="ab" title="Categories" menuVariant="dark">
                <Link
                  to="/Swallow"
                  className="title"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  Cum Swallow
                </Link>

                <Link
                  to="/Facials"
                  className="title"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  Facials
                </Link>

                <Link
                  to="/Cum-On-Feet"
                  className="title"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  Cum On Feet
                </Link>

                <Link
                  to="/Cum-Kissing"
                  className="title"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  Cum Kissing
                </Link>

                <Link
                  to="/Cum-Drink"
                  className="title"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  Cum Drink
                </Link>

                <Link
                  to="/Crempie"
                  className="title"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  Crempie
                </Link>

                <Link
                  to="/Anal-Crempie"
                  className="title"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  Anal Crempie
                </Link>

                <Link
                  to="/Gloryhole"
                  className="title"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  Gloryhole
                </Link>

                <Link
                  to="/Fake-Cum"
                  className="title"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  Fake Cum
                </Link>

                <Link
                  to="/Close-Ups"
                  className="title"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  Close Ups
                </Link>

                <Link
                  to="/Cum-On-Food"
                  className="title"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  Cum On Food
                </Link>

                <Link
                  to="/Cum-On-Hair"
                  className="title"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  Cum On Hair
                </Link>

                <Link
                  to="/Cum-On-Tits"
                  className="title"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  Cum On Tits
                </Link>
                <Link
                  to="/Cum-Spoon"
                  className="title"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  Cum Spoon
                </Link>
                <Link
                  to="/Underwater"
                  className="title"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  Underwater
                </Link>

                <Link
                  to="/Cum-Walk"
                  className="title"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  Cum Walk
                </Link>

                <Link
                  to="/Cum-Covered-Fucking"
                  className="title"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  Cum Covered Fucking
                </Link>

                <Link
                  to="/Bukkake"
                  className="title"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  Bukkake
                </Link>
                <Link
                  to="/Cum-Play"
                  className="title"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  Cum Play
                </Link>
                <Link
                  to="/Cum-Dislike"
                  className="title"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  Cum Dislike
                </Link>
                <Link
                  to="/Cum-Licking"
                  className="title"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  Cum Licking
                </Link>
                <NavDropdown.Divider />

                <Link
                  to=""
                  className="title"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  See All
                </Link>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavBar;
